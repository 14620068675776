import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { FeatureContext } from "shared/components/FeatureProvider";
import Changelog from "shared/components/Modals/Changelog";
import IDS from "shared/constants/ids";
import useModal from "shared/hooks/useModal";
import { IFeatures } from "shared/types/flags";

interface IProps {
  children?: ReactNode;
  isAuthenticated: () => boolean;
}

interface IContext {
  changelogItemCount: number;
  hasUnread: boolean;
  showChangelog?: () => void;
}

interface IChangelogItem {
  date: string;
  details: string;
  feature?: string;
  link?: string;
  name: string;
}

export interface IChangelog {
  [key: string]: IChangelogItem;
}

export const ChangelogContext = createContext<IContext>({
  changelogItemCount: 0,
  hasUnread: false,
});

export const filterChangelogItems = (
  changelogItems: IChangelog,
  features: IFeatures,
): IChangelog =>
  Object.keys(changelogItems)
    .filter((itemKey) => {
      const key = changelogItems[itemKey].feature;
      return !(key && features[key] !== true);
    })
    .reduce<{ [key: string]: IChangelogItem }>(
      (res, key) => ((res[key] = changelogItems[key]), res),
      {},
    );

const ChangelogProvider = ({ children, isAuthenticated }: IProps) => {
  const { features } = useContext(FeatureContext);

  const loggedIn = isAuthenticated();
  const location = useLocation();

  const changelogURI = process.env.CHANGELOG_URI;

  const [changelogItems, setChangelogItems] = useState<IChangelog>();
  const [hasUnread, setHasUnread] = useState(false);
  const [fromLoggedOut, setFromLoggedOut] = useState(!loggedIn);

  const { closeModal, openModal } = useModal(IDS.MODALS.CHANGELOG.ID);

  const calculateUnread = useCallback(() => {
    const lastSeen = localStorage.getItem("changelogLastSeen") || "";

    const filteredItems = filterChangelogItems(changelogItems || {}, features);

    const someUnseen = Object.values(filteredItems).some(
      (item) => item.date > lastSeen,
    );
    setHasUnread(someUnseen);
  }, [changelogItems, features]);

  const showChangelog = useCallback(() => {
    if (!changelogItems) {
      return;
    }

    // Filter out any items that have been feature flagged off
    const filteredItems = filterChangelogItems(changelogItems, features);

    if (Object.keys(filteredItems).length === 0) {
      return;
    }

    openModal(
      <Changelog
        items={filteredItems}
        onClose={() => {
          closeModal();
          calculateUnread();
        }}
      />,
    );
  }, [calculateUnread, changelogItems, closeModal, features, openModal]);

  useEffect(() => {
    if (!changelogURI) {
      return;
    }
    const fetchChangelog = async () => {
      const result = await fetch(changelogURI)
        .then((response) => response.text())
        .catch(() => null);
      if (result) {
        let parsedResult;
        try {
          parsedResult = JSON.parse(result);
        } catch {
          // Broken syntax, ignore changelog items in invalid format
        }
        setChangelogItems(parsedResult as IChangelog);
      }
    };
    fetchChangelog();
  }, [changelogURI]);

  // Calculate if there are unread items
  useEffect(calculateUnread, [calculateUnread]);

  // Show the modal when the user logs in if:
  // - changes are present
  // - user hasn't already seen them
  useEffect(() => {
    if (fromLoggedOut && hasUnread && loggedIn) {
      setFromLoggedOut(false);
      showChangelog();
    }
  }, [fromLoggedOut, hasUnread, location, loggedIn, showChangelog]);

  const changelogItemCount = changelogItems
    ? Object.keys(filterChangelogItems(changelogItems, features)).length
    : 0;

  const contextValue = useMemo(
    () => ({
      changelogItemCount,
      hasUnread,
      showChangelog,
    }),
    [changelogItemCount, hasUnread, showChangelog],
  );

  return (
    <ChangelogContext.Provider value={contextValue}>
      {children}
    </ChangelogContext.Provider>
  );
};

export default ChangelogProvider;
