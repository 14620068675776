import { forwardRef } from "react";
import { Link } from "react-router-dom";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import linkStyles from "shared/styling/Link";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: inline-block;
  position: relative;
  z-index: 1;
`;

export const InvisibleButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const PopoverContent = styled.ul`
  background-color: white;
  border-radius: 0.25rem;
  color: black;
  min-width: 6rem;
  padding: 0.5rem 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 100%;
  &::before {
    content: "";
    bottom: 100%;
    border-color: transparent;
    border-bottom-color: ${COLORS.WHITE};
    border-bottom-width: 0.3rem;
    border-style: solid;
    display: block;
    height: 0;
    position: absolute;
    right: 1.2rem;
    width: 0;
  }
`;

export const itemStyles = css`
  color: ${COLORS.DARK_GREY};
  display: block;
  font-weight: ${FONTS.WEIGHT.BOLD};
  line-height: 0.75;
  padding: 0.5rem 1rem;
  text-align: left;
  width: 100%;
`;

export const StyledButton = styled.button`
  ${linkStyles}
  ${itemStyles}
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  ${linkStyles}
  ${itemStyles}
`;

export const StyledExternalLink = styled.a`
  ${linkStyles}
  ${itemStyles}
`;

export const PopoverButton = forwardRef((props: any, ref) => (
  <li role="none">
    <StyledButton role="menuitem" tabIndex="-1" {...props} ref={ref} />
  </li>
));

export const PopoverLink = forwardRef((props: any, ref) => (
  <li role="none">
    <StyledLink role="menuitem" tabIndex="-1" {...props} ref={ref} />
  </li>
));

export const PopoverExternalLink = forwardRef((props: any, ref) => (
  <li role="none">
    <StyledExternalLink role="menuitem" tabIndex="-1" {...props} ref={ref} />
  </li>
));

export default {
  Container,
  InvisibleButton,
  PopoverContent,
};
