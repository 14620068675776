import COLORS from "shared/constants/colors";
import styled, { css } from "styled-components";

export const dotStyles = css`
  border-radius: 100%;
  height: 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 0.75rem;
`;

export const UpdateNotification = styled.div<{ important?: boolean }>`
  @keyframes dotPulse {
    0% {
      transform: scale(0);
      opacity: 1;
    }

    5% {
      transform: scale(1.5);
      opacity: 1;
    }

    10% {
      transform: scale(2);
      opacity: 0;
    }

    100% {
      transform: scale(0);
      opacity: 0;
    }
  }

  background: ${({ important }) => (important ? COLORS.RED : COLORS.BLUE)};

  ${dotStyles} ::before {
    ${dotStyles}
    animation: ${({ important }) =>
      important ? "dotPulse 10s ease infinite" : "none"};
    background: ${({ important }) => (important ? COLORS.RED : COLORS.BLUE)};
    content: "";
  }
`;

export default UpdateNotification;
