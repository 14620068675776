import { Link } from "react-router-dom";
import Icon from "shared/components/Icon";
import Popover from "shared/components/Popover";
import COLORS from "shared/constants/colors";
import LAYOUT from "shared/constants/layout";
import VidsyLogo from "shared/images/vidsy-logo.svg";
import linkStyles from "shared/styling/Link";
import styled, { css } from "styled-components";

export const AvatarContainer = styled.div`
  position: relative;
`;

export const BrandItems = styled.div`
  grid-area: brand;
  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    margin-left: 4rem;
  }
`;

export const Dashboard = styled(Icon)`
  display: inline-block;
  height: 1.5rem;
  position: relative;
  top: 0.325rem;
  width: 1.5rem;
`;

export const allLinkStyles = css`
  ${linkStyles}
  display: inline-block;
  margin-right: 2rem;
`;

export const NavAnchor = styled.a`
  ${allLinkStyles}
`;

export const NavLink = styled(Link)`
  ${allLinkStyles}
`;

export const InlineNotificationIcon = styled.div<{ important?: boolean }>`
  background: ${({ important }) => (important ? COLORS.RED : COLORS.BLUE)};
  border-radius: 100%;
  display: inline-block;
  height: 0.75rem;
  margin-left: 0.5rem;
  width: 0.75rem;
`;

export const NAV_HEIGHT = "5.75rem";
export const StyledHeader = styled.header`
  align-items: center;
  background: ${COLORS.DARK_GREY};
  column-gap: 2rem;
  display: grid;
  grid-template-areas: "nav nav brand brand user user";
  grid-template-columns: repeat(6, 1fr);
  height: ${NAV_HEIGHT};
  min-width: 45rem;
  padding: 1.25rem 3rem;
  white-space: nowrap;
  width: 100%;

  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    grid-template-areas:
      "brand user user user user user"
      "nav   nav   nav  nav  nav  nav";
    height: auto;
    padding: 0.75rem 0;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 0.25em;
  vertical-align: sub;
`;

export const StyledVidsyLogo = styled(VidsyLogo)`
  color: ${COLORS.WHITE};
  display: block;
  height: auto;
  margin: 0 auto;
  width: 5.125rem;

  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    margin: 0 auto 0 0;
  }
`;

export const UserItems = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  grid-area: user;
  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    padding-right: 1rem;
  }
`;

export const UserPopover = styled(Popover)`
  flex: 0;
  margin-left: 2rem;
  min-height: 2.75rem;
  z-index: 2;
`;
