import textualize from "internal/shared/utils/textualize";
import { ChangeEvent, FocusEvent, forwardRef } from "react";
import Icon from "shared/components/Icon";
import COLORS from "shared/constants/colors";
import CloseIconSvg from "shared/images/icons/close-small.svg";
import SearchIconSvg from "shared/images/icons/search.svg";
import styled, { css } from "styled-components";

export interface IProps {
  id: string;
  className?: string;
  onFocus?: (e: FocusEvent) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  value: string;
}

export const Wrapper = styled.div`
  display: flex;
`;

export const SearchWrapper = styled.div`
  display: inline-block;
  flex: 1;
  height: 1.75rem;
  min-width: 2rem;
  position: relative;
`;

export const ClearButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: ${COLORS.WHITE};
  cursor: pointer;
  margin: 0;
  padding: 0.25rem;
  position: absolute;
  right: 0.375rem;
  top: 0.25rem;
`;

export const ClearIcon = styled(Icon)`
  display: block;
  height: 0.75rem;
  pointer-events: none;
  width: 0.75rem;

  > svg {
    display: block;
  }
`;

export const SearchLabel = styled.label`
  appearance: none;
  background: none;
  border: none;
  color: ${COLORS.WHITE};
  cursor: pointer;
  margin: 0;
  padding: 0.25rem;
  position: absolute;
  left: 0.25rem;
  top: 0.125rem;
`;

export const SearchIcon = styled(Icon)`
  display: block;
  pointer-events: none;

  > svg {
    display: block;
  }
`;

export const searchInputStyles = css`
  -webkit-appearance: textfield;
  appearance: none;
  background: ${COLORS.DARK_GREY};
  border: 1px solid ${COLORS.WHITE};
  border-radius: 2rem;
  font: inherit;
  height: 100%;
  width: 100%;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const SearchInput = styled.input`
  ${searchInputStyles}
  color: ${COLORS.WHITE};
  padding: 0.25rem 0.5rem 0.25rem 1.75rem;
`;

export const SearchBar = forwardRef(
  (
    { className, id, onClear, onFocus, value, ...props }: IProps,
    ref: React.MutableRefObject<HTMLInputElement>,
  ) => (
    <Wrapper className={className} id={id}>
      <SearchWrapper>
        <SearchLabel
          aria-label={textualize("general.search.label") as string}
          htmlFor={`${id}Input`}
        >
          <SearchIcon aria-hidden={true} component={SearchIconSvg} />
        </SearchLabel>
        <SearchInput
          autoComplete="off"
          id={`${id}Input`}
          onFocus={onFocus}
          ref={ref}
          type="search"
          value={value}
          {...props}
        />
        {onClear && value && (
          <ClearButton id={`${id}ClearButton`} onClick={onClear}>
            <ClearIcon
              aria-hidden={true}
              component={CloseIconSvg}
              label={textualize("general.search.clear") as string}
            />
          </ClearButton>
        )}
      </SearchWrapper>
    </Wrapper>
  ),
);

export default SearchBar;
