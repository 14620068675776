import { transparentize } from "polished";
import { buttonStyles } from "shared/components/Button";
import { StandardModal } from "shared/components/Modals";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import baseScrollbarStyles from "shared/styling/BaseScrollbar";
import smallTextStyles from "shared/styling/SmallText";
import styled from "styled-components";

export const ChangelogModal = styled(StandardModal)`
  height: 45rem;
  width: 48.5rem;
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 0;
`;

export const ItemListContainer = styled.div`
  ${baseScrollbarStyles};

  &::-webkit-scrollbar-track {
    background: ${COLORS.DARK_GREY};
  }
  &::-webkit-scrollbar-thumb {
    border: 0.4375rem solid ${COLORS.DARK_GREY};
  }
  scrollbar-color: ${COLORS.WHITE} ${COLORS.DARK_GREY};

  overflow-y: auto;
  max-width: 15rem;
  min-width: 15rem;
`;

export const ItemList = styled.ol``;

export const ItemListItem = styled.li<{ read?: boolean; selected?: boolean }>`
  > button {
    appearance: none;
    background: ${(props) =>
      props.selected ? COLORS.MID_GREY : "transparent"};
    border: none;
    color: ${COLORS.WHITE};
    cursor: pointer;
    display: block;
    font-family: ${FONTS.FAMILY.BODY};
    padding: 1rem;
    text-align: left;
    width: 100%;

    > * {
      opacity: ${(props) => (props.read ? 0.75 : 1)};
    }

    &:focus {
      background: ${(props) =>
        props.selected
          ? COLORS.MID_GREY
          : transparentize(0.5, COLORS.MID_GREY)};
      outline: none;

      > * {
        opacity: 1;
      }
    }

    &:hover {
      background: ${(props) =>
        props.selected
          ? COLORS.MID_GREY
          : transparentize(0.5, COLORS.MID_GREY)};
    }

    strong {
      display: block;
      font-size: 1.125rem;
      font-weight: ${FONTS.WEIGHT.BOLD};
      line-height: 1.375rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      ${smallTextStyles}
      color: ${transparentize(0.25, COLORS.WHITE)};
    }
  }
`;

export const ItemDetailsContainer = styled.div`
  ${baseScrollbarStyles};

  background: ${COLORS.MID_GREY};
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.75rem 1rem 1rem;

  &::after {
    background-image: linear-gradient(
      ${transparentize(1, COLORS.MID_GREY)},
      ${transparentize(0, COLORS.MID_GREY)}
    );
    bottom: -1rem;
    content: "";
    display: block;
    height: 1rem;
    pointer-events: none;
    position: sticky;
    width: 100%;
  }

  h3 {
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
  }

  p {
    line-height: 1.375rem;
    margin-bottom: 1rem;
  }

  img,
  iframe,
  video {
    display: block;
    margin-bottom: 1rem;
    max-width: 100%;
  }

  /* Find out more button */
  > a:last-child {
    display: inline-block;
    margin: 1rem 0;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul,
  ol {
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
`;

export const LinkButton = styled.a`
  ${buttonStyles}
`;
