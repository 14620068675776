import { gql } from "@apollo/client";
import FILTER_OPTIONS from "internal/curation/constants/selectVideoFilterOptions";
import SORT_OPTIONS from "internal/curation/constants/selectVideoSortOptions";
import { ViewOptions } from "internal/shared/fragments/viewOptions";
import {
  GetViewOptionsBriefFieldsFragment as IBrief,
  GetSelectedVideosBriefFieldsFragment as IGetSelectedVideosBrief,
  GetViewOptionsQuery as IGetViewOptions,
  ViewOptionsInput as IViewOptions,
} from "internal/shared/types/graphql";
import { IContext } from "internal/web/services/GraphQL/types/shared";

export const GetViewOptionsBriefFields = gql`
  fragment GetViewOptionsBriefFields on Brief {
    id
    shortHash
    viewOptions {
      ...ViewOptions
    }
  }
  ${ViewOptions}
`;

export const GET_VIEW_OPTIONS = gql`
  query GetViewOptions($shortHash: ID!) {
    brief(shortHash: $shortHash) {
      ...GetViewOptionsBriefFields
    }
  }
  ${GetViewOptionsBriefFields}
`;

export const GetSelectedVideosBriefFields = gql`
  fragment GetSelectedVideosBriefFields on Brief {
    id
    shortHash
    selectedVideoIds
  }
`;

export const GET_SELECTED_VIDEOS = gql`
  query GetSelectedVideos($shortHash: ID!) {
    brief(shortHash: $shortHash) {
      ...GetSelectedVideosBriefFields
    }
  }
  ${GetSelectedVideosBriefFields}
`;

const Type = {
  Brief: {
    selectedVideoIds: (brief: IGetSelectedVideosBrief) =>
      brief.selectedVideoIds || [],
    viewOptions: (brief: IBrief, _: any, { cache }: IContext) => {
      const defaultOptions = {
        __typename: "ViewOptions",
        filter: FILTER_OPTIONS.ALL,
        sort: SORT_OPTIONS.CONCEPT,
      };

      try {
        const result = cache.readQuery({
          query: GET_VIEW_OPTIONS,
          variables: { shortHash: brief.shortHash },
        }) as IGetViewOptions;

        return result.brief.viewOptions || defaultOptions;
      } catch {
        // Brief not found
        return defaultOptions;
      }
    },
  },
};

const Mutation = {
  toggleVideosSelection: (
    _: any,
    args: { id: string; shortHash: string; ids: string[]; selected: boolean },
    { cache }: IContext,
  ) => {
    try {
      const result = cache.readQuery({
        query: GET_SELECTED_VIDEOS,
        variables: { shortHash: args.shortHash },
      });
      const newSelectedVideos = result?.brief?.selectedVideoIds
        ? args.ids.reduce(
            (selectedVideoIds, videoID) => {
              if (args.selected) {
                return [...selectedVideoIds, videoID];
              } else {
                return selectedVideoIds.filter(
                  (existingVideoID) => existingVideoID !== videoID,
                );
              }
            },
            [...result.brief.selectedVideoIds!],
          )
        : args.ids;

      const data = {
        brief: {
          __typename: "Brief",
          id: args.id,
          shortHash: args.shortHash,
          ...result?.brief,
          selectedVideoIds: newSelectedVideos,
        },
      };

      cache.writeQuery({ data, query: GET_SELECTED_VIDEOS });
      return data.brief;
    } catch {
      // Cache not populated with local state
    }
    return;
  },
  updateBriefView: (
    _: any,
    args: { id: string; shortHash: string; viewOptions: IViewOptions },
    { cache }: IContext,
  ) => {
    try {
      const result = cache.readQuery({
        query: GET_VIEW_OPTIONS,
        variables: { shortHash: args.shortHash },
      });

      const data = {
        brief: {
          __typename: "Brief",
          id: args.id,
          shortHash: args.shortHash,
          ...result?.brief,
          viewOptions: {
            __typename: "ViewOptions",
            filter: "",
            sort: "",
            ...result?.brief?.viewOptions,
            ...args.viewOptions,
          },
        },
      };

      cache.writeQuery({
        data,
        query: GET_VIEW_OPTIONS,
        variables: { shortHash: args.shortHash },
      });
      return data.brief;
    } catch {
      // Cache not populated with local state
    }
  },
};

export { Mutation, Type };
