export default {
  BRANDS: {
    ADD_BRAND: "systemBrandsAddBrand",
    BRAND_LINK: "systemBrandsTileBrandLink",
    GRID: "systemBrandsTileGrid",
    IMAGE_LINK: "systemBrandsTileImageLink",
    PAGE: "systemBrandsPage",
    PAGINATION: {
      NEXT: "systemBrandsPaginationNextButton",
      PREVIOUS: "systemBrandsPaginationPreviousButton",
    },
  },
  BRIEFS: {
    ACTIONS_BUTTON: "systemBriefsTileActionsButton",
    BRIEF_LINK: "systemBriefsTileBriefLink",
    CURATION_LINK: "systemBriefsTileCurationLink",
    EDIT_BRAND: "systemBriefsEditBrand",
    OPEN: {
      TABLE: "systemBriefsOpenTable",
    },
    PAGE: "systemBriefsPage",
  },
  CREATOR_MANAGEMENT: {
    NO_CREATORS: "systemCreatorManagementNoCreators",
    PAGE: "systemCreatorManagementPage",
    TABLE: {
      CREATOR_NAME: "systemCreatorManagementCreatorName",
      DOWNLOAD_CSV: "systemCreatorManagementDownloadCSV",
      HEADERS: {
        ALLOCATION_TOTAL: "systemCreatorManagementCreatorTotalHeader",
        BASE: "systemCreatorManagementBaseHeader",
        BONUS: "systemCreatorManagementBonusHeader",
        CREATORS: "systemCreatorManagementCreatorsHeader",
        EXPENSE: "systemCreatorManagementExpenseHeader",
        FILES: "systemCreatorManagementFilesHeader",
        INFORMED: "systemCreatorManagementInformedHeader",
        NOTES: "systemCreatorManagementNotesHeader",
        PAYMENT: "systemCreatorManagementPaymentHeader",
        PAYMENT_TOTAL: "systemCreatorManagementPaymentTotalHeader",
        SELECTED_CREATORS: "systemCreatorManagementSelectedCreatorsHeader",
        SHORTLISTED_SELECTED:
          "systemCreatorManagementShortlistedSelectedHeader",
        SUBMISSION_STATUS: "systemCreatorManagementSubmissionStatusHeader",
        VIDEO_STATUS: "systemCreatorManagementVideoStatusHeader",
        VIDEOS: "systemCreatorManagementVideosHeader",
      },
      ID: "systemCreatorManagementTable",
      INFORMED: "systemCreatorManagementInformed",
      INFORMED_TOOLTIP: "systemCreatorManagementInformedTooltip",
      NOTES: "systemCreatorManagementNotes",
      PAYMENTS: {
        BASE: "systemCreatorManagementPaymentsBase",
        BONUS: "systemCreatorManagementPaymentsBonus",
        EXPENSE: "systemCreatorManagementPaymentsExpense",
        TOTAL: "systemCreatorManagementPaymentsTotal",
      },
      REQUIRED_SUBMISSIONS: "systemCreatorManagementRequiredSubmissions",
      SAVE: "systemCreatorManagementSave",
      SELECTED: "systemCreatorManagementSelected",
      SHORTLISTED: "systemCreatorManagementShortlisted",
      SUBMITTED: "systemCreatorManagementSubmitted",
      SUPPORTING_FILES_STATUS: {
        ID: "systemCreatorManagementSupportingFilesStatus",
        INPUT: "systemCreatorManagementSupportingFilesStatusInput",
      },
      TOTALS: {
        BASE: "systemCreatorManagementTotalsBase",
        BONUS: "systemCreatorManagementTotalsBonus",
        EXPENSE: "systemCreatorManagementTotalsExpense",
        TOTAL: "systemCreatorManagementTotalsTotal",
      },
      VERIFY_PAYMENTS: "systemCreatorManagementVerifyPayments",
    },
    VERIFY_PAYMENT: {
      CANCEL: "systemCreatorManagementVerifyPaymentModalCancelButton",
      CONFIRM: "systemCreatorManagementVerifyPaymentModalConfirmButton",
      ID: "systemCreatorManagementVerifyPaymentModal",
      WARNING: "systemCreatorManagementVerifyPaymentModalWarning",
    },
  },
  DASHBOARD: {
    HEADER: "systemDashboardHeader",
    PAGE: "systemDashboardPage",
    COMPLETED_PROJECTS: {
      ID: "systemDashboardCompletedProjects",
      SECTION: "systemDashboardCompletedProjectsSection",
      TABLE: "systemDashboardCompletedProjectsTable",
    },
    OPEN_PROJECTS: {
      ID: "systemDashboardOpenProjects",
      SECTION: "systemDashboardOpenProjectsSection",
      TABLE: "systemDashboardOpenProjectsTable",
    },
    VIEW_ALL_PROJECTS: "systemDashboardViewAllProjects",
    WELCOME: "systemDashboardWelcome",
  },
  DOWNLOADS: {
    PAGE: "systemDownloadsPage",
    PROGRESS_BAR: "systemDownloadsProgressBar",
  },
  LOGIN: {
    ANIMATION: {
      AUTHOR: "systemLoginPageAnimationAuthor",
      CONTAINER: "systemLoginPageAnimationContainer",
    },
    PAGE: "systemLoginPage",
    SIGN_IN_BUTTON: "systemLoginSignInButton",
    SIGN_IN_ERROR: "systemLoginSignInError",
  },
  MEGA_SEARCH: {
    BRAND_RESULTS: "systemMegaSearchBrandResults",
    BRIEF_RESULTS: "systemMegaSearchBriefResults",
  },
  MODALS: {
    ADD_PROJECT: {
      CANCEL: "systemModalsAddProjectCancel",
      CREATE_PROJECT: "systemModalsAddProjectCreateProject",
      HEADING: "systemModalsAddProjectHeading",
      ID: "systemModalsAddProjectModal",
      JOB_REFERENCE: "systemModalsAddProjectJobReference",
      PRODUCTION_TYPE: {
        CREATORS: "systemModalsAddProjectProductionTypeCreators",
        ID: "systemModalsAddProjectProductionType",
        IN_HOUSE: "systemModalsAddProjectProductionTypeInHouse",
        WARNING: "systemModalsAddProjectProductionTypeInHouseWarning",
      },
      TITLE: "systemModalsAddProjectTitle",
      USE_NEW_CLIENT_PLATFORM: "systemModalsAddProjectUseNewClientPlatform",
    },
    UPSERT_BRAND: {
      ADD_BRAND: {
        BUTTON: "systemModalsUpsertBrandAddBrandButton",
        HEADING: "systemModalsUpsertBrandAddBrandHeading",
      },
      BRAND_LOGO: "systemModalsUpsertBrandBrandLogo",
      BRAND_NAME: "systemModalsUpsertBrandBrandName",
      CANCEL: "systemModalsUpsertBrandCancel",
      DELETE_CONTACT: "systemModalsUpsertBrandDeleteContact",
      DISPLAY_NAME: "systemModalsUpsertBrandDisplayName",
      EDIT_BRAND: {
        HEADING: "systemModalsUpsertBrandEditBrandHeading",
        SAVE: "systemModalsUpsertBrandEditBrandSave",
      },
      EDIT_CONTACT: "systemModalsUpsertBrandEditContact",
      EMAIL: "systemModalsUpsertBrandEmail",
      ID: "systemModalsUpsertBrand",
      JOB_TITLE: "systemModalsUpsertBrandJobTitle",
    },
  },
  PROJECTS: {
    ADD_PROJECT: "systemProjectsAddProject",
    COMPLETED: {
      SECTION: "systemProjectsCompletedSection",
      TABLE: "systemProjectsCompletedTable",
    },
    OPEN: {
      SECTION: "systemProjectsOpenSection",
      TABLE: "systemProjectsOpenTable",
    },
  },
  SEARCH_TILE: {
    BRAND_NAME: "systemSearchTileBrandName",
    BRIEF_NAME: "systemSearchTileBriefName",
    JOB_REFERENCE: "systemSearchTileJobReference",
    SHORT_HASH: "systemSearchTileShortHash",
  },
};
