import { useQuery } from "@apollo/client";
import Session from "internal/web/services/Session";
import { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/components/Avatar";
import { ChangelogContext } from "shared/components/ChangelogProvider";
import {
  AvatarContainer,
  BrandItems,
  Dashboard,
  InlineNotificationIcon,
  NavLink,
  StyledHeader,
  StyledVidsyLogo,
  UserItems,
  UserPopover,
} from "shared/components/GlobalNav/styles";
import { default as NavItems } from "shared/components/GlobalNavItems";
import {
  PopoverButton,
  PopoverExternalLink,
} from "shared/components/Popover/PopoverComponents";
import { UpdateAvailableContext } from "shared/components/UpdateAvailableProvider";
import UpdateDot from "shared/components/UpdateDot";
import SHARED_ANALYTICS from "shared/constants/analytics";
import EXTERNAL_LINKS from "shared/constants/externalLinks";
import IDS from "shared/constants/ids";
import HomeIcon from "shared/images/icons/home.svg";
import { GET_VIEWER } from "shared/queries/viewer";
import Auth from "shared/services/InternalAuth";
import { ViewerQuery as IViewer } from "shared/types/graphql";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import textualize from "shared/utils/textualize";
import { StyledMegaSearch } from "./styles";

function GlobalNav() {
  const {
    changelogItemCount,
    hasUnread: newChangelogItems,
    showChangelog,
  } = useContext(ChangelogContext);

  const updateAvailable = useContext(UpdateAvailableContext);

  const { data, loading, error } = useQuery<IViewer>(GET_VIEWER);

  const changelogRef = useRef(null);
  const productUpdatesRef = useRef(null);
  const updateRef = useRef(null);
  const signOutRef = useRef(null);

  function updateApp() {
    window.location.reload();
  }

  function signOut() {
    Auth.logout();
    Session.clear();
  }

  if (loading || error || !data) {
    return null;
  }

  const { viewer: user } = data;

  return (
    <StyledHeader id={IDS.GLOBAL_NAV.HEADER}>
      <NavItems>
        <li>
          <NavLink
            aria-label={textualize("nav.dashboard") as string}
            id={IDS.GLOBAL_NAV.DASHBOARD}
            onClick={() =>
              fireAnalyticsEvent(
                SHARED_ANALYTICS.CATEGORIES.DASHBOARD,
                SHARED_ANALYTICS.EVENTS.NAVIGATED_TO_DASHBOARD,
              )
            }
            to={{
              pathname: "/",
              state: {
                clearCache: true,
              },
            }}
          >
            <Dashboard component={HomeIcon} />
          </NavLink>
        </li>
        <li>
          <NavLink
            id={IDS.GLOBAL_NAV.BRANDS}
            to={{
              pathname: "/brands",
              state: {
                clearCache: true,
              },
            }}
          >
            {textualize("nav.brands")}
          </NavLink>
        </li>
        <li>
          <NavLink
            id={IDS.GLOBAL_NAV.CREATORS}
            to={{
              pathname: "/creators",
              state: {
                clearCache: true,
              },
            }}
          >
            {textualize("nav.creators")}
          </NavLink>
        </li>
        <li>
          <NavLink
            id={IDS.GLOBAL_NAV.PAYMENTS}
            to={{
              pathname: "/payments/awaiting",
              state: {
                clearCache: true,
              },
            }}
          >
            {textualize("nav.payments")}
          </NavLink>
        </li>
        <li>
          <NavLink
            id={IDS.GLOBAL_NAV.INSPO}
            to={{
              pathname: "/inspo",
              state: {
                clearCache: true,
              },
            }}
          >
            {textualize("nav.inspo")}
          </NavLink>
        </li>
      </NavItems>
      <BrandItems>
        <Link
          aria-label={`${textualize("nav.dashboard")}`}
          id={IDS.GLOBAL_NAV.VIDSY_LOGO}
          to={{
            pathname: "/",
            state: {
              clearCache: true,
            },
          }}
        >
          <StyledVidsyLogo />
        </Link>
      </BrandItems>
      <UserItems>
        <StyledMegaSearch id={IDS.GLOBAL_NAV.MEGA_SEARCH} />
        {!loading && data && (
          <UserPopover
            id={IDS.GLOBAL_NAV.USER}
            label={textualize("nav.userItems") as string}
            trigger={
              <AvatarContainer>
                {(updateAvailable || newChangelogItems) && (
                  <UpdateDot
                    aria-label={
                      updateAvailable
                        ? (textualize("nav.badge.important") as string)
                        : (textualize("nav.badge.notification") as string)
                    }
                    important={!!updateAvailable}
                    role="status"
                  />
                )}
                <Avatar>
                  {user.image && <img alt={user.name} src={user.image} />}
                </Avatar>
              </AvatarContainer>
            }
          >
            {updateAvailable && (
              <PopoverButton
                id={IDS.GLOBAL_NAV.UPDATE}
                onClick={updateApp}
                ref={updateRef}
              >
                {textualize("nav.update")}
                <InlineNotificationIcon important />
              </PopoverButton>
            )}
            {!!changelogItemCount && (
              <PopoverButton
                id={IDS.GLOBAL_NAV.WHATS_NEW}
                onClick={
                  showChangelog
                    ? () => {
                        fireAnalyticsEvent(
                          SHARED_ANALYTICS.CATEGORIES.CHANGELOG,
                          SHARED_ANALYTICS.EVENTS.CHANGELOG_MANUALLY_OPENED,
                        );
                        showChangelog();
                      }
                    : undefined
                }
                ref={changelogRef}
              >
                {textualize("nav.whatsNew")}
                {newChangelogItems && <InlineNotificationIcon />}
              </PopoverButton>
            )}
            <PopoverExternalLink
              href={EXTERNAL_LINKS.PRODUCT_UPDATES}
              id={IDS.GLOBAL_NAV.PRODUCT_UPDATES}
              onClick={() => {
                fireAnalyticsEvent(
                  SHARED_ANALYTICS.CATEGORIES.CHANGELOG,
                  SHARED_ANALYTICS.EVENTS.CHANGELOG_NOTION_LINK_CLICKED,
                  {
                    source: "nav",
                  },
                );
              }}
              ref={productUpdatesRef}
              rel="noopener"
              target="_blank"
            >
              {textualize("nav.productUpdates")}
            </PopoverExternalLink>
            <PopoverButton
              id={IDS.GLOBAL_NAV.SIGN_OUT}
              onClick={signOut}
              ref={signOutRef}
            >
              {textualize("nav.signOut")}
            </PopoverButton>
          </UserPopover>
        )}
      </UserItems>
    </StyledHeader>
  );
}

export default GlobalNav;
