import {
  cache,
  link,
  resolvers,
  typeDefs,
} from "internal/web/services/GraphQL";
import getClient from "shared/clients/ApolloClient";

export const client = getClient({
  cache,
  link,
  resolvers,
  typeDefs,
});

export default client;
