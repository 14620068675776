import { Auth0DecodedHash, WebAuth } from "auth0-js";
import { IAuthConfig } from "shared/types/services/Auth";

export default class Auth {
  private auth: WebAuth;
  private config: IAuthConfig;

  constructor(config: IAuthConfig) {
    this.config = config;
    const { audience, clientID, domain, redirectUri } = this.config;

    this.auth = new WebAuth({
      audience,
      clientID,
      domain,
      redirectUri,
      responseType: "token id_token",
      scope: "openid profile email",
    });
  }

  // SSO or other external authentication
  public authorize(connection?: string) {
    const { domain } = this.config;
    const { host, protocol } = window.location;

    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.popup.authorize(
        {
          connection,
          domain,
          redirectUri: `${protocol}//${host}/login/callback`,
          responseType: "token id_token",
          scope: "openid profile email",
        },
        (err, result) => {
          if (err?.name === "SyntaxError") {
            // JS issue parsing a response not from Auth0
            // https://github.com/auth0/auth0.js/issues/512
            return;
          }

          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  public authorizeCallback(hash: string) {
    this.auth.popup.callback({ hash });
  }

  // Direct email/password authentication
  public authorizeWithCredentials(email: string, password: string) {
    const { realm } = this.config;

    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.client.login(
        {
          password,
          realm: realm || "",
          username: email,
          scope: "openid profile email",
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  public logout(returnPath?: string) {
    let returnTo = returnPath;

    if (returnTo === undefined) {
      const { host, protocol } = window.location;

      returnTo = `${protocol}//${host}/login`;
    }

    const { clientID } = this.config;
    this.auth.logout({
      clientID,
      returnTo,
    });
  }

  // send email with code to user
  public passwordlessSend(email: string) {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.passwordlessStart(
        {
          authParams: {
            audience: "https://vidsy.co",
            scope: "openid profile email",
          },
          connection: "email",
          email,
          send: "code",
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  // send code to Auth0
  public passwordlessLogin(code: string, email: string) {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.passwordlessLogin(
        {
          connection: "email",
          email: email,
          verificationCode: code,
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  public passwordlessCallback(hash: string) {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.parseHash({ hash }, function (err, authResult) {
        if (err) {
          return reject(err);
        }

        if (!authResult) {
          return reject();
        }

        resolve(authResult);
      });
    });
  }

  public reauthenticate() {
    const { host, protocol } = window.location;
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.checkSession(
        {
          redirectUri: `${protocol}//${host}/login`,
          scope: "openid profile email",
        },
        (err, authResult) => {
          if (err) {
            return reject(err);
          }
          resolve(authResult);
        },
      );
    });
  }
}
