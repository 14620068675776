import { ApolloClient, ApolloLink } from "@apollo/client";

interface IClientConfig {
  cache?: any;
  link: ApolloLink;
  resolvers?: any;
  typeDefs?: any;
}

function getClient({ cache, link, resolvers, typeDefs }: IClientConfig) {
  return new ApolloClient({
    cache,
    link,
    resolvers,
    typeDefs,
  });
}

export default getClient;
