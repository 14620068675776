import { format, parseISO } from "date-fns";
import { marked } from "marked";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IChangelog } from "shared/components/ChangelogProvider";
import { ModalWrapper } from "shared/components/Modals";
import { CancelButton, ControlsRow } from "shared/components/Modals/styles";
import ANALYTICS from "shared/constants/analytics";
import EXTERNAL_LINKS from "shared/constants/externalLinks";
import IDS from "shared/constants/ids";
import KEYBOARD_SHORTCUTS from "shared/constants/shortcuts/keyboardShortcuts";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import textualize from "shared/utils/textualize";
import {
  ChangelogModal,
  Container,
  ItemDetailsContainer,
  ItemList,
  ItemListContainer,
  ItemListItem,
  LinkButton,
} from "./styles";

export interface IProps {
  items: IChangelog;
  onClose: () => void;
}

const Changelog = ({ items, onClose }: IProps) => {
  const [lastSeen, setLastSeen] = useState(
    localStorage.getItem("changelogLastSeen") || "",
  );
  const [hasRead, setHasRead] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const firstItemRef = useRef<HTMLButtonElement>();

  const sortedItems = useMemo(
    () => Object.values(items).sort((a, b) => (a.date < b.date ? 1 : -1)),
    [items],
  );
  const selectedItem = sortedItems[selectedItemIndex];

  const viewItem = useCallback(
    (index: number) => {
      if (index === selectedItemIndex) {
        return;
      }
      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.CHANGELOG,
        ANALYTICS.EVENTS.CHANGELOG_ARTICLE_SELECTED,
      );
      setSelectedItemIndex(index);
    },
    [selectedItemIndex],
  );

  const numberOfItems = sortedItems.length;
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      let newIndex: number;
      if (
        [
          KEYBOARD_SHORTCUTS.ARROW_DOWN,
          KEYBOARD_SHORTCUTS.ARROW_RIGHT,
        ].includes(e.key)
      ) {
        newIndex =
          selectedItemIndex === numberOfItems - 1 ? 0 : selectedItemIndex + 1;
        viewItem(newIndex);
      } else if (
        [KEYBOARD_SHORTCUTS.ARROW_LEFT, KEYBOARD_SHORTCUTS.ARROW_UP].includes(
          e.key,
        )
      ) {
        newIndex =
          selectedItemIndex === 0 ? numberOfItems - 1 : selectedItemIndex - 1;
        viewItem(newIndex);
      }
      return;
    },
    [selectedItemIndex, numberOfItems, viewItem],
  );

  useEffect(() => {
    const hasSeenTimeout = setTimeout(() => {
      setLastSeen(sortedItems[0].date);
      setHasRead(true);
      localStorage.setItem("changelogLastSeen", sortedItems[0].date);
    }, 2000);
    return () => {
      clearTimeout(hasSeenTimeout);
    };
  });

  useLayoutEffect(() => {
    if (firstItemRef.current) {
      firstItemRef.current.focus();
    }
  }, []);

  const onModalClose = () => {
    if (!hasRead) {
      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.CHANGELOG,
        ANALYTICS.EVENTS.CHANGELOG_CLOSED_EARLY,
      );
    }
    onClose();
  };

  return (
    <ModalWrapper id={IDS.MODALS.CHANGELOG.ID}>
      <ChangelogModal>
        <h2>{textualize("modals.changelog.title")}</h2>
        <Container>
          <ItemListContainer>
            <ItemList id={IDS.MODALS.CHANGELOG.ITEM_LIST} role="tablist">
              {sortedItems.map((item, index) => (
                <ItemListItem
                  key={item.name}
                  read={item.date <= lastSeen}
                  selected={index === selectedItemIndex}
                >
                  <button
                    onClick={() => viewItem(index)}
                    onKeyDown={onKeyDown}
                    ref={
                      index === 0
                        ? (firstItemRef as React.MutableRefObject<HTMLButtonElement>)
                        : undefined
                    }
                    role="tab"
                    tabIndex={-1}
                  >
                    <strong>{item.name}</strong>
                    <span>{format(parseISO(item.date), "do MMMM y")}</span>
                  </button>
                </ItemListItem>
              ))}
            </ItemList>
          </ItemListContainer>
          <ItemDetailsContainer id={IDS.MODALS.CHANGELOG.ITEM_DETAILS}>
            <h3>{selectedItem.name}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: marked(selectedItem.details),
              }}
            />
            {!!selectedItem.link && (
              <LinkButton
                href={selectedItem.link}
                id={IDS.MODALS.CHANGELOG.FIND_OUT_MORE}
                onClick={() => {
                  fireAnalyticsEvent(
                    ANALYTICS.CATEGORIES.CHANGELOG,
                    ANALYTICS.EVENTS.CHANGELOG_ARTICLE_LINK_CLICKED,
                  );
                }}
                rel="noopener"
                target="_blank"
              >
                {textualize("modals.changelog.findOutMore")}
              </LinkButton>
            )}
          </ItemDetailsContainer>
        </Container>
        <ControlsRow>
          <CancelButton
            id={IDS.MODALS.CHANGELOG.CLOSE}
            onClick={onModalClose}
            role="button"
          >
            {textualize("modals.changelog.close")}
          </CancelButton>
          <LinkButton
            href={EXTERNAL_LINKS.PRODUCT_UPDATES}
            id={IDS.MODALS.CHANGELOG.VIEW_ALL}
            onClick={() => {
              fireAnalyticsEvent(
                ANALYTICS.CATEGORIES.CHANGELOG,
                ANALYTICS.EVENTS.CHANGELOG_NOTION_LINK_CLICKED,
                {
                  source: "modal",
                },
              );
            }}
            rel="noopener"
            target="_blank"
          >
            {textualize("modals.changelog.viewAll")}
          </LinkButton>
        </ControlsRow>
      </ChangelogModal>
    </ModalWrapper>
  );
};

export default Changelog;
